exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-api-tsx": () => import("./../../../src/pages/api.tsx" /* webpackChunkName: "component---src-pages-api-tsx" */),
  "component---src-pages-example-tsx": () => import("./../../../src/pages/example.tsx" /* webpackChunkName: "component---src-pages-example-tsx" */),
  "component---src-pages-getting-started-index-tsx": () => import("./../../../src/pages/getting-started/index.tsx" /* webpackChunkName: "component---src-pages-getting-started-index-tsx" */),
  "component---src-pages-getting-started-store-setup-tsx": () => import("./../../../src/pages/getting-started/store-setup.tsx" /* webpackChunkName: "component---src-pages-getting-started-store-setup-tsx" */),
  "component---src-pages-getting-started-using-store-tsx": () => import("./../../../src/pages/getting-started/using-store.tsx" /* webpackChunkName: "component---src-pages-getting-started-using-store-tsx" */),
  "component---src-pages-getting-started-working-with-queues-tsx": () => import("./../../../src/pages/getting-started/working-with-queues.tsx" /* webpackChunkName: "component---src-pages-getting-started-working-with-queues-tsx" */),
  "component---src-pages-getting-started-working-with-singles-tsx": () => import("./../../../src/pages/getting-started/working-with-singles.tsx" /* webpackChunkName: "component---src-pages-getting-started-working-with-singles-tsx" */),
  "component---src-pages-getting-started-working-with-tables-tsx": () => import("./../../../src/pages/getting-started/working-with-tables.tsx" /* webpackChunkName: "component---src-pages-getting-started-working-with-tables-tsx" */),
  "component---src-pages-getting-started-working-with-triggers-tsx": () => import("./../../../src/pages/getting-started/working-with-triggers.tsx" /* webpackChunkName: "component---src-pages-getting-started-working-with-triggers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installation-tsx": () => import("./../../../src/pages/installation.tsx" /* webpackChunkName: "component---src-pages-installation-tsx" */)
}

